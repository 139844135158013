/** @jsx jsx */

import BackgroundImage from 'gatsby-background-image';
import ComponentFactory from '../components/ComponentFactory';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

export const query = graphql`
  query partners {
    prismic {
      page(lang: "en-ca", uid: "partners") {
        title
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
        body {
          ... on PRISMIC_PageBodyServices {
            fields {
              feature_image
              feature_imageSharp {
                childImageSharp {
                  fluid(maxHeight: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  fixed(width: 133) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              feature_link {
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              feature_short_description
              feature_name
              feature_link_text
            }
            primary {
              feature_title
              feature_layout
              feature_description
            }
          }
        }
      }
    }
  }
`;

export default function Index({
  data: {
    prismic: { page },
  },
}) {
  // console.log('------- data --------->', page);
  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>Dome GeoConsulting Partners</title>
        <meta name="description" content="Valued partners of domegeo.ca" />
      </Helmet>
      <Layout>
        {page.hero_imageSharp && (
          <BackgroundImage
            className="bgr"
            sx={{ minHeight: [180, 250, 500] }}
            fluid={page.hero_imageSharp.childImageSharp.fluid}
          />
        )}
        <section
          sx={{ width: ['90%', '90%', '80%'], margin: 'auto', mb: [1, 2, 4] }}
        >
          <RichText render={page.title} />
          {page.description && <RichText render={page.description} />}
          {page.body &&
            page.body.map((component, i) => (
              <ComponentFactory
                key={i}
                {...component}
                type={component.primary.feature_layout}
              />
            ))}
        </section>
      </Layout>
    </Fragment>
  );
}
